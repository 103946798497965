export const WORDS = [
  'matos',
  'josep',
  'bravo',
  'ronda',
  'ulloa',
  'aleix',
  'didac',
  'borja',
  'cinco',
  'nacho',
  'mandi',
  'alves',
  'oblak',
  'totti',
  'villa',
  'golpe',
  'ronda',
  'marca',
  'jason',
  'trejo',
  'lucas',
  'perea',
  'aitor',
  'dieta',
  'saque',
  'rojas',
  'salvi',
  'lopez',
  'alava',
  'betis',
  'salas',
  'juego',
  'salva',
  'balon',
  'vidal',
  'linea',
  'koffi',
  'kenan',
  'derbi',
  'emery',
  'soler',
  'inter',
  'falta',
  'final',
  'folch',
  'linea',
  'local',
  'depor',
  'lazio',
  'masia',
  'sacar',
  'botas',
  'vence',
  'fidel',
  'juego',
  'navas',
  'hoppe',
  'ficha',
  'mario',
  'fuera',
  'roger',
  'pedri',
  'henry',
  'himno',
  'rodri',
  'celta',
  'silva',
  'tacos',
  'pasar',
  'edgar',
  'jesus',
  'campo',
  'yuste',
  'pedro',
  'chema',
  'cadiz',
  'banda',
  'vesga',
  'poste',
  'jovic',
  'simon',
  'josan',
  'aspas',
  'kroos',
  'quini',
  'tello',
  'alamo',
  'milan',
  'savic',
  'munir',
  'saque',
  'betis',
  'angel',
  'balon',
  'color',
  'fekir',
  'derbi',
  'galan',
  'xerez',
  'goles',
  'eibar',
  'karim',
  'oblak',
  'semis',
  'ruben',
  'ramos',
  'matos',
  'josep',
  'bravo',
  'pique',
  'prima',
  'betis',
  'copas',
  'tongo',
  'ronda',
  'ulloa',
  'aleix',
  'didac',
  'borja',
  'cinco',
  'nacho',
  'mandi',
  'alves',
  'oblak',
  'totti',
  'villa',
  'golpe',
  'ronda',
  'marca',
  'jason',
  'trejo',
  'lucas',
  'perea',
  'aitor',
  'dieta',
  'saque',
  'rojas',
  'salvi',
  'lopez',
  'alava',
  'salas',
  'juego',
  'salva',
  'balon',
  'vidal',
  'linea',
  'koffi',
  'kenan',
  'derbi',
  'emery',
  'soler',
  'inter',
  'falta',
  'final',
  'folch',
  'linea',
  'local',
  'depor',
  'lazio',
  'masia',
  'sacar',
  'botas',
  'vence',
  'fidel',
  'juego',
  'navas',
  'hoppe',
  'ficha',
  'mario',
  'fuera',
  'roger',
  'pedri',
  'henry',
  'himno',
  'rodri',
  'celta',
  'silva',
  'tacos',
  'pasar',
  'edgar',
  'jesus',
  'campo',
  'yuste',
  'pedro',
  'chema',
  'cadiz',
  'banda',
  'vesga',
  'poste',
  'jovic',
  'simon',
  'josan',
  'aspas',
  'kroos',
  'quini',
  'tello',
  'alamo',
  'milan',
  'savic',
  'munir',
  'saque',
  'betis',
  'angel',
  'balon',
  'color',
  'fekir',
  'derbi',
  'galan',
  'xerez',
  'goles',
  'eibar',
  'rober',
  'perez',
  'enric',
  'ruben',
  'ramos',
]
